.root {
  position: relative;
  border: solid 1px red;
  container-type: inline-size;
  width: 100%;
}

.root img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.textTop,
.textBottom {
  position: absolute;
  right: 0;
  left: 0;
  color: white;
  font-weight: 900;
  text-align: center;
  text-shadow: black 2px 2px 3px;
  text-transform: uppercase;
}

.textTop {
  top: 1cqh;
  font-size: 4cqw;
}
.textBottom {
  bottom: 1cqh;
  font-size: 5cqw;
}
