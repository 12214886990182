.manufacturer {
  opacity: 70%;
  font-size: 1rem;
}

.special::before {
  display: inline-block;
  animation: wiggle 2s linear infinite;
  content: "Note: ";
  color: color-mix(in hsl, blue, black 15%);
  font-weight: bold;
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}

.notes:before {
  padding-right: 0.5em;
  content: "note:";
  color: #aaa;
  font-weight: bold;
  text-indent: -4em;
}
