.root {
  position: relative;
}

.root img {
  border-radius: 24px;
  background-color: white;
  width: 48px;
  height: 48px;
}

h2 .root {
  :global(.certDot) {
    font-size: 70%;
  }
  .officerStar,
  .role {
    display: none;
  }
}

.officerStar {
  position: absolute;
  top: -5px;
  right: -5px;
  color: black;
  font-size: 20px;
  line-height: 20px;
  -webkit-text-stroke: 1px white;
}
